@use 'sass:map';
@use '../../theme/spacing' as *;
@use '../../theme/mediaqueries' as *;
@use '../../theme/font-weight' as *;
@use '../../theme/font-family' as *;

%headline {
  font-family: $font-family-headline;
  font-style: normal;
  font-weight: $font-weight-regular;
  white-space: pre-wrap;
  font-feature-settings: normal;
}

.headline-s {
  @extend %headline;

  font-size: 1.25rem;
  line-height: 140%;

  @media (min-width: $mq-desktop) {
    font-size: 1.5rem;
  }
}

.headline-m {
  @extend %headline;

  font-size: 1.625rem;
  line-height: 120%;

  @media (min-width: $mq-desktop) {
    font-size: 2.25rem;
  }
}

.headline-l {
  @extend %headline;

  font-size: 2rem;
  line-height: 110%;

  @media (min-width: $mq-desktop) {
    font-size: 4rem;
  }
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}
