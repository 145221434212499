$shadow-small:
  0 0 0.5rem rgb(0 0 0 / 8%),
  -0.25rem 0.25rem 0.5rem rgb(0 0 0 / 8%);
$shadow-medium:
  0 0 0.5rem rgb(0 0 0 / 8%),
  -0.5rem 0.5rem 1rem rgb(0 0 0 / 8%);
$shadow-large:
  0 0 0.5rem rgb(0 0 0 / 8%),
  -1rem 1rem 2.5rem rgb(0 0 0 / 8%);
$shadow-fixed-top:
  0 0 0.5rem rgb(0 0 0 / 8%),
  0 -1rem 2.5rem rgb(0 0 0 / 8%);
$shadow-fixed-right:
  0 0 0.5rem rgb(0 0 0 / 8%),
  1rem 0 2.5rem rgb(0 0 0 / 8%);
$shadow-fixed-bottom:
  0 0 0.5rem rgb(0 0 0 / 8%),
  0 1rem 2.5rem rgb(0 0 0 / 8%);
$shadow-fixed-left:
  0 0 0.5rem rgb(0 0 0 / 8%),
  -1rem 0 2.5rem rgb(0 0 0 / 8%);
