@use '../../theme/colors' as *;
@use '../../theme/border' as *;
@use '../../theme/shadows' as *;
@use '../../theme/animations' as *;
@use '../../theme/z-indexes' as *;
@use '../../theme/mixins/focus-ring' as *;
@use '../../theme/spacing' as *;

.tooltip-content {
  position: fixed;
  z-index: $z-index-tooltip;
  top: 0;
  left: 0;
  /* stylelint-disable-next-line meowtec/no-px */
  max-width: calc(min(50vw, 300px) - 1rem);
  max-height: calc(50vh - 1rem);
  padding: $spacing-xs;
  transition: opacity $small-intro;
  border-radius: $border-radius-s;
  opacity: 0;
  background-color: $color-white;
  box-shadow: $shadow-medium;
  white-space: normal;
  pointer-events: none;
}

.trigger {
  visibility: hidden;

  &:focus-visible {
    @include focus-ring;
  }

  &:hover + .tooltip-content,
  &:focus-visible + .tooltip-content {
    transition: opacity $small-outro;
    opacity: 1;
  }
}

.trigger-visible {
  visibility: visible;
}
