@use '../../theme/colors' as *;
@use '../../theme/spacing' as *;

.field-footer {
  display: flex;
  align-items: center;
  line-height: 170%;
}

.state-error {
  color: $color-red;
}

.state-success {
  color: $color-green;
}

.state-default {
  color: $color-dark-gray;
}

.icon {
  display: flex;
  position: relative;
  margin-right: $spacing-xxs;

  & > * {
    vertical-align: middle;
  }
}
